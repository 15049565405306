import React from 'react'
import { connect } from 'react-redux'
import FormHandler from '@/components/form-handler'
import { withModalManager } from '../modal-manager'
import DatePicker from 'react-datepicker'
import { createReferralCode, getPaymentPlans } from '@/lib/redux/actions'
import { selectPaymentPlans } from '@/lib/redux/selectors'
import { withRestricted } from '@/components/restricted'
import { AFFILIATE_COVERAGE_TYPES_SUPPORTED } from '@/lib/constants'

class ReferralCodeForm extends React.Component {
  constructor (props) {
    super(props)

    const { affiliate } = props

    this.state = {
      expiryDate: null,
      affiliateCoverageTypeSupported:
        AFFILIATE_COVERAGE_TYPES_SUPPORTED[affiliate.type],
      error: null
    }
    this.handleChangeExpiryDate = this.handleChangeExpiryDate.bind(this)
  }

  handleChangeExpiryDate (date) {
    this.setState({ expiryDate: date })
  }

  get selectedExpiryDate () {
    return this.state.expiryDate
  }

  componentDidMount () {
    const { getPaymentPlans } = this.props

    getPaymentPlans()
  }

  onSubmit (values) {
    const { createReferralCode, reset, affiliate } = this.props
    const { expiryDate } = this.state

    const params = {
      affiliateID: affiliate.id,
      expiryDate,
      ...values,
      requiredEmailVerificationDomains:
        values.requiredEmailVerificationDomains || []
    }

    return createReferralCode(params)
      .then(referralCode => {
        reset()
        this.setState({
          referralCode: referralCode.code,
          expiryDate: null
        })
      })
      .catch(error => {
        if (error.response && error.response.status === 422) {
          this.setState({ error: error.response.data.error })
        }
      })
  }

  render () {
    const {
      handleSubmit,
      handleChange,
      submitting,
      paymentPlans,
      values,
      modal,
      children
    } = this.props
    const { referralCode, affiliateCoverageTypeSupported, error } = this.state

    return (
      <>
        {children({ toggle: modal.toggle })}

        <modal.Modal>
          <modal.Header>Referral Code</modal.Header>

          <form onSubmit={handleSubmit(values => this.onSubmit(values))}>
            <modal.Body>
              <div className='form-group'>
                <label>Code</label>
                <input
                  type='text'
                  name='code'
                  value={values.code || ''}
                  className='form-control'
                  onChange={handleChange}
                  placeholder='eg. INVOY60'
                  required
                />
              </div>

              <div className='form-group'>
                <label>External Description</label>
                <input
                  type='text'
                  name='externalDescription'
                  value={values.externalDescription || ''}
                  className='form-control'
                  onChange={handleChange}
                  placeholder=''
                  required
                />
              </div>

              <div className='form-group'>
                <label>Internal Description</label>
                <input
                  type='text'
                  name='internalDescription'
                  value={values.internalDescription || ''}
                  className='form-control'
                  onChange={handleChange}
                  placeholder=''
                  required
                />
              </div>

              <div className='form-group'>
                <label>Coverage Type</label>
                <p className='text-muted'>
                  This field specifies the plan type of coverage to which the
                  referral code will be applied.
                </p>
                <ul className='text-muted'>
                  {affiliateCoverageTypeSupported.map(coverage => (
                    <li key={coverage.value}>
                      <strong>{coverage.label}</strong> - {coverage.description}
                    </li>
                  ))}
                </ul>

                <select
                  name='coverageType'
                  className='custom-select'
                  onChange={handleChange}
                  value={values.coverageType}
                  required
                >
                  <option value=''>- Select -</option>
                  {affiliateCoverageTypeSupported.map(coverage => (
                    <option value={coverage.value} key={coverage.value}>
                      {coverage.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group'>
                <label>Payment Plan</label>
                <select
                  name='paymentPlanID'
                  className='custom-select'
                  onChange={handleChange}
                  value={values.paymentPlanID}
                  required
                >
                  <option value=''>- Select -</option>
                  {paymentPlans.map(plan => (
                    <option value={plan.id} key={plan.id}>
                      {plan.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group'>
                <label>Expiration Date</label>
                <DatePicker
                  className='form-control d-inline mr-3'
                  placeholderText='MM/DD/YYYY'
                  selected={this.selectedExpiryDate}
                  onChange={this.handleChangeExpiryDate}
                  todayButton='Today'
                  monthsShown={2}
                />
              </div>

              <div className='form-group'>
                <label>Required Email Domains</label>
                <input
                  type='array'
                  name='requiredEmailVerificationDomains'
                  value={values.requiredEmailVerificationDomains || []}
                  className='form-control'
                  onChange={handleChange}
                  placeholder='eg. google.com'
                />
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='vip'
                    checked={!!values.vip}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  VIP
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='waitlistRegistration'
                    checked={!!values.waitlistRegistration}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Waitlist Registration
                </label>
              </div>

              {error && <p className='text-danger'>{error}</p>}

              <modal.Footer>
                {referralCode && (
                  <p className='mr-auto'>
                    <span className='text-success'>Success!</span>
                    <br />
                    <strong>Code:</strong> {referralCode}
                  </p>
                )}

                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={submitting}
                >
                  {submitting ? 'Submitting...' : 'Submit'}
                </button>

                <modal.Cancel />
              </modal.Footer>
            </modal.Body>
          </form>
        </modal.Modal>
      </>
    )
  }
}

function mapStateToProps (state, _props) {
  return {
    paymentPlans: selectPaymentPlans(state)
  }
}

const mapDispatchToProps = {
  createReferralCode,
  getPaymentPlans
}

const form = FormHandler(ReferralCodeForm)
const connected = connect(mapStateToProps, mapDispatchToProps)(form)
const modaled = withModalManager(connected)

export default withRestricted(
  modaled,
  'device::new_clients::corporate_access_code'
)
