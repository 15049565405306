import React, { useState } from 'react'
import { connect } from 'react-redux'
import FormHandler from '../form-handler'
import { withModalManager } from '../modal-manager'
import { withRestricted } from '../restricted'
import { createPaymentPlan } from '../../lib/redux/actions'
import { toCents } from '../../lib/functions'

function AddPaymentPlan (props) {
  const {
    children,
    modal,
    handleSubmit,
    handleChange,
    values,
    reset,
    createPaymentPlan,
    error,
    submitting
  } = props
  const [errors, setErrors] = useState()

  const onSubmit = values => {
    return createPaymentPlan({
      ...values,
      monthlyPriceCents: toCents(values.monthlyPrice),
      signupFeeCents: toCents(values.signupFee)
    })
      .then(() => {
        reset()
        modal.hide()
      })
      .catch(err => {
        if (err?.response?.data?.errors) {
          setErrors(err.response.data.errors)
        }

        throw err
      })
  }

  return (
    <>
      {children({ toggle: modal.toggle })}

      <modal.Modal>
        <modal.Header>Add Payment Plan</modal.Header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <modal.Body>
            <div className='form-group'>
              <label>Name</label>
              <input
                type='text'
                name='name'
                value={values.name || ''}
                className='form-control'
                onChange={handleChange}
                placeholder='eg. Acme free plan'
                required
              />
            </div>

            <div className='form-group'>
              <label>Monthly price</label>
              <input
                type='number'
                name='monthlyPrice'
                value={values.monthlyPrice || ''}
                className='form-control'
                onChange={handleChange}
                placeholder='eg. 99'
                required
              />
              {errors?.monthly_price && (
                <p className='text-danger'>{errors.monthly_price}</p>
              )}
            </div>

            <div className='form-group'>
              <label>Trial days</label>
              <input
                type='number'
                name='trialDays'
                value={values.trialDays || ''}
                className='form-control'
                onChange={handleChange}
                placeholder='eg. 15'
                required
              />
              {errors?.trial_days && (
                <p className='text-danger'>{errors.trial_days}</p>
              )}
            </div>

            <div className='form-group'>
              <label>Signup fee</label>
              <input
                type='number'
                name='signupFee'
                value={values.signupFee || ''}
                className='form-control'
                onChange={handleChange}
                placeholder='eg. 299'
                required
              />
              {errors?.signup_fee && (
                <p className='text-danger'>{errors.signup_fee}</p>
              )}
            </div>

            <div className='form-group'>
              <label>Billing interval</label>

              <div className='d-block'>
                <div className='form-control d-inline-block'>1 Month</div>
              </div>
            </div>

            <div className='form-check'>
              <label>
                <input
                  type='checkbox'
                  name='internalTest'
                  checked={!!values.internalTest}
                  onChange={handleChange}
                  className='form-check-input'
                />
                Test plan <br />
                <small className='text-soft'>
                  If this is checked, accounts using this plan won't be
                  accounted for metrics, etc.
                </small>
              </label>
            </div>

            {error && (
              <p className='text-danger'>{error.response.data.error}</p>
            )}
          </modal.Body>

          <modal.Footer>
            <button
              className='btn btn-primary'
              type='submit'
              disabled={submitting}
            >
              {submitting ? 'Saving...' : 'Save'}
            </button>

            <modal.Cancel />
          </modal.Footer>
        </form>
      </modal.Modal>
    </>
  )
}

const mapDispatchToProps = {
  createPaymentPlan
}
const form = FormHandler(AddPaymentPlan, {
  initialValues: {
    monthlyPrice: '0',
    trialDays: '0',
    signupFee: '0',
    internalTest: true,
    enterprise: false
  }
})
const connected = connect(null, mapDispatchToProps)(form)
const modaled = withModalManager(connected)

export default withRestricted(modaled, 'device::new_clients::accounts::create')
