export const AGE_REQUIREMENTS = ['age_range', 'date_of_birth', 'year_of_birth']

export const DEVICE_ASSIGNMENT = ['shipped', 'orientation', 'express_pickup']

export const LAST_NAME_REQUIREMENTS = ['full', 'initial']

export const REGISTRATION_FLOW_TYPE = ['personal', 'corporate', 'clinic', 'vip']

export const INVITATION_EMAIL_TEMPLATE_IDS = [
  'system-invite-to-join-enterprise-bofa'
]

export const AFFILIATE_COVERAGE_TYPES_SUPPORTED = {
  health: [
    {
      label: 'Self-pay',
      value: 'self_pay',
      description:
        'Patients who pay for services directly, without using insurance coverage.'
    },
    {
      label: 'Insurance',
      value: 'health_insurance',
      description:
        'Patients with insurance coverage eligible for CPT claims (e.g., Blue Cross Blue Shield).'
    }
  ],
  enterprise: [
    {
      label: 'Self-pay',
      value: 'self_pay',
      description:
        'Employees who pay for services out-of-pocket, without employer assistance.'
    },
    {
      label: 'Enterprise',
      value: 'enterprise',
      description:
        'Employees whose services are covered by their employer-provided benefits.'
    }
  ],
  individual: [
    {
      label: 'Self-pay',
      value: 'self_pay',
      description:
        'Individuals paying directly for services without external coverage.'
    }
  ]
}

export const EXPRESS_PICKUP_EMAIL_TEMPLATE_IDS = [
  'system-express-device-pickup-bofa-phoenix-dep'
]

export const FEDEX_SERVICES = [
  { slug: 'fedex_2day', name: 'FedEx 2Day®' },
  {
    slug: 'fedex_standard_overnight',
    name: 'FedEx Standard Overnight®',
    expedited: true
  },
  {
    slug: 'fedex_priority_overnight',
    name: 'FedEx Priority Overnight®',
    expedited: true
  },
  {
    slug: 'fedex_first_overnight',
    name: 'FedEx First Overnight®',
    expedited: true
  }
]

export const UPS_SERVICES = [
  {
    slug: 'ups_next_day_air_early_am',
    name: 'UPS Next Day Air® Early',
    expedited: true
  },
  { slug: 'ups_next_day_air', name: 'UPS Next Day Air®', expedited: true },
  {
    slug: 'ups_next_day_air_saver',
    name: 'UPS Next Day Air Saver®',
    expedited: true
  },
  { slug: 'ups_2nd_day_air', name: 'UPS 2nd Day Air®' }
]

export const PRODUCT_TYPES = {
  BASE_UNIT: 'base_unit',
  MOUTH_PIECE: 'mouth_piece',
  PART: 'part',
  KIT: 'kit'
}

export const SUBSCRIPTION_STATUSES = {
  registered: 'Registered',
  registered_waitlisted: 'Registered (waitlist)',
  trialing: 'Trialing',
  active: 'Active',
  paused: 'Paused',
  canceled: 'Canceled'
}
